

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { base_url, img_url } from '../api/index';
import soundFile from '../assets/sound.mp3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewOrders = () => {
    const [orders, setOrders] = useState([]);
    const [hasNewOrders, setHasNewOrders] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const audioRef = useRef(null);
    const audioIntervalRef = useRef(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${base_url}/qr/orders/all`, getAuthHeaders());
                const fetchedOrders = response.data.tables.flatMap(table =>
                    table.table_orders.map(order => ({
                        ...order,
                        tableName: table.name
                    }))
                );
                setOrders(fetchedOrders);
                setHasNewOrders(fetchedOrders.length > 0);
            } catch (error) {
                console.error('Error fetching orders', error);
            }
        };

        fetchOrders();
        const interval = setInterval(fetchOrders, 15000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (hasNewOrders) {
            toast.info('Yeni sifarişiniz var', {
                position: toast.POSITION?.TOP_RIGHT || 'top-right',
                autoClose: 5000,
            });

            if (audioRef.current) {
                // Ensure audio is not already playing
                if (audioRef.current.paused) {
                    audioRef.current.play().catch(error => {
                        console.error('Error playing audio:', error);
                    });
                }
                // Set up an interval to play the audio every 2 seconds
                audioIntervalRef.current = setInterval(() => {
                    audioRef.current.play().catch(error => {
                        console.error('Error playing audio:', error);
                    });
                }, 2000);
            }
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset audio to start
            }

            // Clear the interval if no new orders
            if (audioIntervalRef.current) {
                clearInterval(audioIntervalRef.current);
                audioIntervalRef.current = null;
            }
        }

        // Clean up on component unmount
        return () => {
            if (audioIntervalRef.current) {
                clearInterval(audioIntervalRef.current);
            }
        };
    }, [hasNewOrders]);

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOrderAction = async (orderId, action) => {
        try {
            if (action === 'accept') {
                await axios.post(`${base_url}/qr/orders/${orderId}/approve`, {}, getAuthHeaders());
            } else if (action === 'reject') {
                await axios.delete(`${base_url}/qr/orders/${orderId}`, getAuthHeaders());
            }
            window.location.reload();
        } catch (error) {
            console.error(`Error handling order action ${action}`, error);
        }
    };

    return (
        <>
            {hasNewOrders && (
                <button
                    onClick={handleButtonClick}
                    className="py-2 px-4 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-300 bg-red-500 text-white animate-pulse"
                >
                    Yeni sifarisiniz var ({orders.length})
                </button>
            )}

            {isModalOpen && (
                <div className="fixed max-w-mobile inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full h-[80vh] overflow-y-auto">
                        <button
                            onClick={handleCloseModal}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl"
                        >
                            &times;
                        </button>
                        <h2 className="text-2xl font-bold mb-4">Yeni sifarislər</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
                            {orders.map(order => (
                                <div key={order.id} className="border border-gray-200 rounded-lg p-4 flex flex-col items-center bg-white shadow-sm">
                                    <h3 className="text-xl font-semibold mb-2">{order.tableName}</h3>
                                    {order.order.stocks[0]?.image && (
                                        <img
                                            src={`${img_url}/${order.order.stocks[0]?.image}`}
                                            alt={order.order.stocks[0]?.name}
                                            className="my-2 max-w-full h-auto rounded-md"
                                        />
                                    )}
                                    <p className="text-lg font-medium mb-1">{order.order.stocks[0]?.name}</p>
                                    <p className="text-gray-600">Mikdar: {order.order.stocks[0]?.pivot.quantity}</p>
                                    <p className="text-gray-600">Toplam: {order.order.stocks[0]?.pivot.quantity * order.order.stocks[0]?.price} ₼</p>
                                    <div className="mt-4 flex space-x-4">
                                        <button
                                            onClick={() => handleOrderAction(order.id, 'accept')}
                                            className="bg-green-500 text-white py-1 px-4 rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                                        >
                                            Təsdiq edin
                                        </button>
                                        <button
                                            onClick={() => handleOrderAction(order.id, 'reject')}
                                            className="bg-red-500 text-white py-1 px-4 rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                                        >
                                            Ləğv edin
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Audio element */}
            <audio ref={audioRef} src={soundFile} preload="auto" loop />
            {/* <ToastContainer /> */}
        </>
    );
};

const getAuthHeaders = () => {
    const token = localStorage.getItem("token");
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
};

export default NewOrders;
