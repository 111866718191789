

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccessDenied from './AccessDenied';
import { base_url } from '../api/index';

// Function to get authorization headers
const getAuthHeaders = () => {
    const token = localStorage.getItem("token");
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    };
};

function AddStok({ setAddStok }) {
    const [formData, setFormData] = useState({
        name: '',
        stock_group_id: '',
        image: null,
        show_on_qr: false,
        price: 0,
        amount: 0,
        alert_critical: false,
        critical_amount: 1,
        // order_start: '00:00',
        // order_stop: '24:00',
        item_type: 'sayilan', // Default value for item type
    });

    const [groups, setGroups] = useState([]);
    const [accessDenied, setAccessDenied] = useState(false);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get(`${base_url}/stock-groups`, getAuthHeaders());
                setGroups(response.data);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prevData => ({
                ...prevData,
                image: file
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('stock_group_id', formData.stock_group_id);
        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }
        formDataToSend.append('show_on_qr', formData.show_on_qr ? '1' : '0');
        formDataToSend.append('price', formData.price);
        formDataToSend.append('amount', formData.amount);
        formDataToSend.append('alert_critical', formData.alert_critical ? '1' : '0');
        formDataToSend.append('critical_amount', formData.critical_amount);
        // formDataToSend.append('order_start', formData.order_start);
        // formDataToSend.append('order_stop', formData.order_stop);
        formDataToSend.append('item_type', formData.item_type); // Add item_type to FormData

        try {
            await axios.post(`${base_url}/stocks`, formDataToSend, {
                ...getAuthHeaders(),
                headers: {
                    ...getAuthHeaders().headers,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setAddStok(false);
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
                setAccessDenied(true);
            } else {
                console.error('Error adding stock:', error);
                alert('An error occurred while adding the stock. Please try again later.');
            }
        }
    };

    if (accessDenied) return <AccessDenied onClose={setAccessDenied} />;

    return (
        <form onSubmit={handleSubmit} className='flex flex-col md:flex-row gap-4 w-full'>
            <div className='bg-gray-50 rounded border p-3 w-full md:w-1/2'>
                <div className='flex flex-col mb-5'>
                    <h3 className='mb-2'>Resim (.jpg , .png max:2048 kb)</h3>
                    <input
                        className='border rounded py-2 px-3 w-full text-sm font-medium'
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                        accept=".jpg,.png"
                        required
                    />
                </div>
                <div className='border rounded flex items-center py-2 px-5 w-full bg-white mb-5'>
                    <input
                        className='mr-3 h-6'
                        type="checkbox"
                        name="show_on_qr"
                        checked={formData.show_on_qr}
                        onChange={handleChange}
                    />
                    <label className='text-sm font-semibold' htmlFor="qr">QR menüde göster</label>
                </div>
                <div className='flex flex-col mb-5'>
                    <h3 className='mb-2'>Adı</h3>
                    <input
                        className='border rounded py-2 px-3 w-full text-sm font-medium'
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col mb-5'>
                    <h3 className='mb-2'>Grup</h3>
                    <select
                        className='border rounded py-2 px-3 w-full text-sm font-medium'
                        name="stock_group_id"
                        value={formData.stock_group_id}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Seçiniz</option>
                        {groups.map(group => (
                            <option key={group.id} value={group.id}>{group.name}</option>
                        ))}
                    </select>
                </div>
                <div className='flex flex-col mb-5'>
                    <h3 className='mb-2'>Ürün Tipi</h3>
                    <select
                        className='border rounded py-2 px-3 w-full text-sm font-medium'
                        name="item_type"
                        value={formData.item_type}
                        onChange={handleChange}
                        required
                    >
                        <option value="sayilan">Sayilan</option>
                        <option value="sayilmiyan">Sayilmiyan</option>
                    </select>
                </div>
                <button type="submit" className='bg-sky-600 font-medium py-2 px-4 rounded text-white'>
                    Saxla
                </button>
            </div>
            <div className='bg-gray-50 rounded border p-3 w-full md:w-1/2'>
                    <div className='flex flex-col mb-3'>
                        <h3 className='mb-2'>Satış qiyməti</h3>
                        <div className='flex'>
                            <input
                                className='border rounded-l py-2 px-3 w-10/12 text-sm font-medium'
                                type="number"
                                name="price"
                                value={formData.price}
                                onChange={handleChange}
                                step="0.01"
                                required
                            />
                            <div className='border border-l-0 bg-gray-50 text-center w-2/12 rounded-r py-2 px-3 '>₼</div>
                        </div>
                    </div>
            {formData.item_type === 'sayilan' && (
                <>
                    <div className='flex flex-col mb-3'>
                        <h3 className='mb-2'>Miqtar</h3>
                        <div className='flex'>
                            <input
                                className='border rounded py-2 px-3 w-10/12 text-sm font-medium'
                                type="number"
                                name="amount"
                                value={formData.amount}
                                onChange={handleChange}
                                required
                            />
                            <div className='border border-l-0 bg-gray-50 text-center w-2/12 rounded-r py-2 px-3 '>Adet</div>
                        </div>
                    </div>
                    <div className='flex flex-col mb-3'>
                        <h3 className='mb-2'>Kritik say (kritik sayi altına düştüğünde xəbərdarliq edəcek.)</h3>
                        <div className='flex'>
                            <div className='border bg-gray-50 border-r-0 rounded-l flex items-center py-2 px-5 w-2/5'>
                                <input
                                    className='mr-3 h-6'
                                    type="checkbox"
                                    name="alert_critical"
                                    checked={formData.alert_critical}
                                    onChange={handleChange}
                                />
                                <label className='text-sm font-semibold' htmlFor="stokTakip">Kritik xəbərdarliq aktiv</label>
                            </div>
                            <input
                                className='border rounded-r py-2 px-3 w-3/5 text-sm font-medium'
                                type="number"
                                name="critical_amount"
                                value={formData.critical_amount}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                
                </>
            )}
                    {/* <div className='flex flex-col mb-3'>
                        <h3 className='mb-2'>Sipariş saatleri</h3>
                        <div className='border flex items-center bg-gray-50 p-3'>
                            <input
                                className='border rounded py-2 px-3 w-1/2 text-sm font-medium'
                                type="time"
                                name="order_start"
                                value={formData.order_start}
                                onChange={handleChange}
                                required
                            />
                            <span className='mx-3'>-</span>
                            <input
                                className='border rounded py-2 px-3 w-1/2 text-sm font-medium'
                                type="time"
                                name="order_stop"
                                value={formData.order_stop}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div> */}
                </div>
        </form>
    );
}

export default AddStok;
